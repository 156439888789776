import "vue-multiselect/dist/vue-multiselect.min.css"


import OjMultiselect from "@/components/oj-multiselect.vue";

import {add_vueApp2} from "@/packs/vue_helper.js";
import OrganizationGroupSupervisorsComponent from "@/components/organization/organization-group-supervisors-component.vue";

add_vueApp2('organization-group-supervisors-component', OrganizationGroupSupervisorsComponent)

add_vueApp2('component-oj-multiselect-courses', OjMultiselect)
add_vueApp2("component-oj-multiselect-products", OjMultiselect)
add_vueApp2('component-courses-multiselect-category', OjMultiselect)
document.addEventListener('DOMContentLoaded', () => {



    const elements = document.querySelectorAll('.oj-multiselect-component');
    elements.forEach((element) => {
        add_vueApp2(element.id, OjMultiselect);
    });
})