<template>
  <div>
    ooo
    {{}}


      <table class="table table-bordered">
        <thead>
          <tr>
            <th colspan="2">Název</th>
            <th>qty</th>

            <th>částka s DPH</th>
            <th>Základ osvobozený od daně</th>
            <th>Základ daně</th>
            <th>Výše DPH</th>
            <th colspan="2"></th>
          </tr>

<!--  také ještě není - nejlépe do sloupečku PLATBA nebo k tomu produktu přímo
- první částka bude částka s DPH,
další je Základ osvobozený od daně, Základ daně, Výše DPH, Výše slevy (ta už tam je) - máme to sice u plateb, ale chceme to vidět i v objednávkách,
tak prosím stejně udělat i tam-->

        </thead>
        <tbody>
          <tr v-for="item in order_items" :key="item.oid" :data-oid="item.oid" :data-id="item.id">

            <td><img :src="item.small_image_url" v-if="item.small_image_url" class="icoproduct"></td>
            <td>{{item.name}}</td>
            <td class="numeros1"><input type="number" class="form-control" v-model="item.payment_subtotal.quantity"/></td>
            <td class="numeros"><input type="number" class="form-control"  v-model="item.payment_subtotal.amount"/></td>
            <td class="numeros"><input type="number" class="form-control"  v-model="item.payment_subtotal.exempt"/></td>
            <td class="numeros"><input type="number" class="form-control"  v-model="item.payment_subtotal.standard_base"/></td>
            <td class="numeros"><input type="number" class="form-control"  v-model="item.payment_subtotal.vat"/></td>

            <td><a @click="removeItem(item)"  title="odstranit" class="text-danger"><svg class="svg-inline--fa fa-trash fa-w-14" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z"></path></svg></a></td>
            <td class="debuginfo">{{item.id}}</td>

          </tr>


          <tr v-if="payment.discount_as_item" >
            <td></td>
            <td>Sleva  <b>{{payment.discount_code}}</b></td>
            <td>1</td>
            <td>{{payment.discount_amount * -1}}</td>
            <td>0</td>
            <td>{{-(payment.discount_amount - discount_vat() ) }}   </td>
            <td>{{ - discount_vat()  }} </td>
            <td colspan="2"></td>
          </tr>



          <tr>
            <th colspan="3">Celkem</th>
            <th>{{suma - (payment.discount_as_item ? payment.discount_amount : 0)}}</th>

            <th></th>
          </tr>

        </tbody>
      </table>
      <input v-model="order_items_json" name="order_items" type="hidden"/>


    <div class="form-group row mt-2">
      <!--%= it_form.label :product_id, class: 'col-2 col-xl-1 col-form-label' %-->
      <div class="col-6">
        <!--%= it_form.select :product_id, options_for_select(Product.all.map { |pr| [pr.name, pr.id] }, it_form.object.product_id), { include_blank: true }, { class: 'form-control', data: { controller: 'selectize' } } %-->
        <label>Přidat produkt</label>
        <v-select label="name" :filterable="true" :options="products"  v-model="selected_product" placeholder="Add Product" @input="onProductSelect">

          <template #option="option">
            <div class="d-center">
              <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
            </div>
          </template>
          <template #selected-option="option">
            <div class="selected d-center">
              <img :src="option.small_image_url" v-if="option.small_image_url" class="icoproduct"> {{ option.name }}
            </div>
          </template>
        </v-select>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

function broofa() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
    var r = Math.random()*16|0, v = c == 'x' ? r : (r&0x3|0x8);
    return v.toString(16);
  });
}


export default {
  name: "order-items",
  data(){
    return{
      selected_product:null,
      products:[],
      order_items:[],
      order_items_json: ''
    }
  },
  props:['items', 'paymentitems', 'payment'],
  watch:{
    order_items:{
      handler(){
        this.order_items_json = JSON.stringify(this.order_items)
      },
      deep:true
    }

  },
  computed:{
    suma(){
      var sum=0;
      for(let i in this.order_items) {
        var q = 1
        if (this.order_items[i].payment_subtotal) q = this.order_items[i].payment_subtotal.quantity
        sum += parseFloat(this.order_items[i].subtotal) * q
      }
      return sum
    },


  },
  methods:{

    discount_vat(){
      let vat_percent = 21
      let discount_amount = this.payment.discount_amount

      let d_vat = discount_amount - (discount_amount / (1 + (vat_percent / 100)))

      return Math.round(d_vat*100)/100
    },


    refreshSuma(){
      this.$parent.total_price = this.suma
    },
    loadProducts(){

      axios.get('/api/products').then( response =>{
        this.products = response.data
      })
    },

    get_product_prices(product_id, next){
      axios.get('/api/products/prices', {params: {product_id: product_id}}).then( response =>{
        next(response.data.prices)
      })
    }
    ,onProductSelect(a){
      console.log('SEL', this.selected_product.id, this.selected_product.name)
      this.get_product_prices(this.selected_product.id, (prices)=>{
        this.order_items.push(this.create_item(prices))
        this.selected_product={}
        this.refreshSuma()
      })

    },

    create_item(prices){
      let p =  this.selected_product
      const item={
        oid: broofa(),
        quantity: 1,
        product_id: p.id,
        small_image_url: p.small_image_url,
        name: p.name,
        vat_rate: p.vat_rate,
        subtotal: parseFloat(p.price_czk)>0 ? p.price_czk : p.price_eur,
        payment_subtotal:{
          product_id: p.id,
          quantity: 1,
          amount: parseFloat(prices.full.standard_base) + parseFloat(prices.full.exempt) + parseFloat(prices.full.vat),
          exempt: parseFloat(prices.full.exempt),
          standard_base: parseFloat(prices.full.standard_base),
          vat: parseFloat(prices.full.vat)
        }
      }

      console.log("add item", item)

      return item
    },

    removeItem(item){
      for(let i in this.order_items){
        if (this.order_items[i].id == item.id){
          this.order_items.splice(i,1)
        }
      }
    },

    subtotalFromPayments(p){
      if (!this.paymentitems) return

      for(var i in this.paymentitems){
        const pi = this.paymentitems[i]
        if (pi.product_id == p.product_id){
          return pi.amount
        }
      }

    },
    get_payment_subtotal(p){
      if (!this.paymentitems) return {}

      for(var i in this.paymentitems){
        const pi = this.paymentitems[i]
        if (pi.product_id == p.product_id){
          return pi
        }
      }
      return {}
    }
  },
  mounted(){

    for(var i in this.items){
      let p =  this.items[i]
      const item={
        id: p.id,
        oid: p.id,
        product_id: p.product_id,
        small_image_url: p.product.small_image_url,
        name: p.product.name,
        subtotal: p.subtotal ? p.subtotal : this.subtotalFromPayments(p),
        vat_rate: p.product.vat_rate,
        payment_subtotal:  this.get_payment_subtotal(p)
      }

      this.order_items.push(item)
    }

  //  this.order_items_json = JSON.stringify(this.order_items)
    this.loadProducts()
  }
}
</script>

<style scoped>
td.numeros1 {max-width:80px; width:80px;}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>