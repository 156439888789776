
// import StudentReport from '../components/report.vue'
import StudentsTableDetail from '../components/StudentsTableDetail.vue'

import StudentAssignments from "../components/StudentAssignments.vue";
import OrderStatusUpdate from "../components/order-status-update.vue";
import SelectStudent from "../components/select-student.vue";
import OrderItems from "../components/order-items.vue";

//import Vue from 'vue/dist/vue.esm'

//import vmodal from 'vue-js-modal'
import "vue-select/dist/vue-select.css";
import vSelect from "vue-select";
//import Toasted from 'vue-toasted';
import VueCustomTooltip from '@adamdehaven/vue-custom-tooltip'
import SelectProduct from "../components/select-product.vue";
import RefundDialog from "../components/refund-dialog.vue";

import PaymentSubtotals from "../components/payment-subtotals.vue";


//import datePicker from 'vue-bootstrap-datetimepicker';
//import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';

import OrganizationHelpers from "../components/OrganizationHelpers.vue";

import WooLog2 from "../components/woo-log2.vue";

import SystemStatistics from "../components/system-statistics.vue";

import TestSender from "../components/TestSender.vue";
import TypeformAcOptions from "../components/typeform-ac-options.vue";

import OrganizationStudents from "../components/organization-students.vue";

import NotifyFilters from '../components/notifications/filters.vue'
import axios from "axios";

import Marketing from "../components/marketing.vue";
//import VueTabs from 'vue-nav-tabs/dist/vue-tabs'
//import 'vue-nav-tabs/themes/vue-tabs.css'

import Ojsyncshow from "../components/oj-sync-show.vue"
import AppSubscrbtionChnages from "../components/app-subscrbtion-chnages.vue";

import CcCalendar from "../components/conversation-club/cc-calendar.vue";
import CcTopics from "../components/conversation-club/cc-topics.vue";

import '../components/conversation-club/kk.scss'
import CcStudents from "../components/conversation-club/cc-students.vue";
import CcLectors from "../components/conversation-club/cc-lectors.vue";

import OjCourses from "../components/oj/courses.vue";

import 'vue-material-design-icons/styles.css';
import ShoppingCart from "../components/shopping-cart/shopping-cart.vue";
import AppGopayReturnUpsell from "../components/shopping-cart/app-gopay-return-upsell.vue";
import Openai from "../components/paja/openai.vue";
import DiscountCodes from "../components/DiscountCodes.vue";
import PajaRequestlog from "../components/paja/paja-requestlog.vue";
import PartnersImport from "../components/pertners/partnersImport.vue";
import Vocabulary from "../components/paja/vocabulary.vue";

import CoursesTranslator from "../components/paja/courses-translator.vue";
import AppLektorProfil from "../components/conversation-club/app-lektor-profil.vue";
import AppKkLessons from "../components/conversation-club/app-kk-lessons.vue";
import FgEditor from "../components/form-generator/fg-editor.vue";
import FormGenerarorApp from "../components/form-generator/FormGenerarorApp.vue";

import Orders from "../components/tables/orders.vue";
import DevicesTokens from "../components/devices-tokens.vue";

import AppAcListLog from "../components/tables/app-ac-list-log.vue";
import StudentDevicesTokens from '../components/student-devices-tokens.vue'

import Payments from "../components/tables/payments.vue";
import NotificationsLog from "../components/tables/notifications-log.vue";
import ApiLogKonverzky from "../components/tables/api-log-konverzky.vue";
import StudentsTable from "../components/tables/students.vue";

import AppKkStats from "../components/conversation-club/app-kk-stats.vue";

import ProductPathEditor from "../components/ProductPathEditor.vue";
import ProductsTable from "../components/tables/products.vue";

import CartSetPassword from "../components/shopping-cart/set-password.vue";
import AppProductUpsellEditor from "../components/app-product-upsell-editor.vue";
import AppProductSkumapping from "../components/app-product-skumapping.vue";

import OjDatePicker from "@/components/oj-date-picker.vue";

import SubscriptionFormProduct from "@/components/subscription-form-product.vue";

import StudentsStats from "../components/statistics/students-stats.vue";

//Vue.use(VueTabs)
import { add_vueApp2 } from './vue_helper'


function add_vueApp(id, components={}){
 /* if (document.getElementById(id)){
    const a = new Vue({
      components: components
    })
    a.$mount('#'+id);




  }*/
}


window.addEventListener('load', () => {

    /*
     Vue.use(Toasted, {
       position: "top-center",
       duration : 10000,
       type: 'success'
     });
   });
   */
    /*
    Vue.use(vmodal)
    Vue.component('v-select', vSelect)

    Vue.use(VueCustomTooltip, {
      name: 'VueCustomTooltip',
      color: '#fff',
      background: '#101040',
      borderRadius: 5,
      fontWeight: 400,
    })

    */

    //document.addEventListener('DOMContentLoaded', () => {
    //const app = new Vue({el: '#reports-app',  components: { StudentReport } })


    add_vueApp2('component-order-status-update', OrderStatusUpdate)
    add_vueApp2('student-detail-app', StudentsTableDetail)

    const csfr_token = document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    axios.defaults.headers.common['X-CSRF-TOKEN'] = csfr_token;
    axios.defaults.withCredentials = true

    add_vueApp2('shopping-cart-set-password-app', CartSetPassword)

    add_vueApp2('app-table-products', ProductsTable)

    add_vueApp2('app-product-path-editor', ProductPathEditor)

    add_vueApp2('order-actions', RefundDialog)

    add_vueApp2('app-aorganization-buttons', OrganizationHelpers)

    //add_vueApp('app-orders', {WooLog2})

    add_vueApp2('app-orders2', WooLog2)

    add_vueApp2('app-subscrbtion-chnages', AppSubscrbtionChnages);
    add_vueApp2('app-kk-calendar', CcCalendar)

    add_vueApp2('app-kk-topics', CcTopics)
    add_vueApp2('app-kk-students', CcStudents)

    add_vueApp2('app-kk-lectors', CcLectors)

    add_vueApp2('app-oj-courses', OjCourses)

    add_vueApp2('shopping-cart-app', ShoppingCart)
    add_vueApp2('openai-app', Openai)

    add_vueApp2('app-discount-codes', DiscountCodes)


    add_vueApp2('openai-app-requestlog', PajaRequestlog)


    add_vueApp2('partners-import-app', PartnersImport)

    add_vueApp2('app-lektor-profil', AppLektorProfil)

    add_vueApp2('app-oj-paja-courses', CoursesTranslator)


    add_vueApp2('app-oj-vocabulary', Vocabulary)


    add_vueApp2('app-kk-lessons', AppKkLessons)

    add_vueApp2('app-kk-stats', AppKkStats)

    add_vueApp2('app-formgen1', FgEditor)

    add_vueApp2('app-formgen-app', FormGenerarorApp)

    add_vueApp2('app-table-orders-app', Orders)

    add_vueApp2('app-devices-tokens', DevicesTokens)

    add_vueApp2('app-ac-list-log', AppAcListLog)

    add_vueApp2('app-student-devices', StudentDevicesTokens)


    //  add_vueApp('sidebar-infopanel', {SystemStatistics})

    add_vueApp2('organization-show-card-app', OrganizationStudents)

    add_vueApp2('typeform-ac-options-app', TypeformAcOptions)

    //add_vueApp('student-kurzy-app', {StudentAssignments, Ojsyncshow})
    add_vueApp2('component-ojsyncshow', Ojsyncshow)
    add_vueApp2('component-student-assignments', StudentAssignments)


    //add_vueApp('vue-woo-api-log', {WooApiLog})

    add_vueApp2('component-payment-subtotals', PaymentSubtotals)


    add_vueApp('app-notify-filter', {NotifyFilters})

    add_vueApp2('app-marketing', Marketing)


    add_vueApp2('payments-table-app', Payments);

    add_vueApp2('app-notifications-log', NotificationsLog);
    add_vueApp2('konverzky-api-log', ApiLogKonverzky);

    add_vueApp2('app-students-table', StudentsTable);


    //   app-product-upsell-editor

    add_vueApp2('app-product-upsell-editor', AppProductUpsellEditor)
    add_vueApp2('app-product-skumapping', AppProductSkumapping)

    add_vueApp2('app-gopay-return-upsell', AppGopayReturnUpsell)


    add_vueApp2('component-date-picker1', OjDatePicker)
    add_vueApp2('component-date-picker2', OjDatePicker)

    add_vueApp2('component-order-items', OrderItems)


    add_vueApp2('component-subscription-form-product', SubscriptionFormProduct)


})