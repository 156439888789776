<script>
import axios from 'axios'
export default {
  name: "dashboard-topseller",
  props: ['month', 'year'],

  data(){
    return({
      top_sellers: [],
      last_year_top_sellers: []
    })
  },
  mounted() {
    this.loadTopSellers()
  },
  methods:{
    loadTopSellers(){
      axios.get('/students_stats/top10.json', {params: {month: this.month, year: this.year}}).then(response =>{
        this.top_sellers = response.data.top10
        this.last_year_top_sellers = response.data.top10_year_ago

      })
    } ,
    stejny_produkt_pred_rokem(seller_name){
      for(let i in this.last_year_top_sellers) {
        if (this.last_year_top_sellers[i].name == seller_name) {
          return this.last_year_top_sellers[i].total
        }
      }
    }
  }
}
</script>

<template>
<table class="minitable">
  <thead>
    <tr>
      <th>Name</th>
      <th>{{ year }}</th>
      <th>{{ year - 1}}</th>
    </tr>
  </thead>
  <tbody>
    <tr v-for="seller in top_sellers">
      <td>{{ seller.name }}</td>
      <td>{{ seller.students_count }}</td>
      <td>{{ stejny_produkt_pred_rokem(seller.name) }}</td>
    </tr>
  </tbody>
</table>
</template>

<style  lang="scss">
.chart {
  .minitable {
    font-size: 80%;

    td {

    }
  }
}
</style>